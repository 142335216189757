import './home.css';

import Button from 'components/button';
import { domain } from 'config';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInitData } from 'store/ducks/app/actions';

import {
    getHomepage,
    getHomepageIdle,
    getPosts,
    getPostsClear,
    getPostsIdle,
} from 'store/ducks/homepage/actions';
import { StateStatus } from 'types/models';

import Masonry from 'react-masonry-component';
import StoryCard from 'components/story_card';
import { Helmet } from 'react-helmet';

declare global {
    interface Window {
        Swiper: any;
    }
}

type Props = {};

const Home = (props: Props) => {
    const dispatch = useDispatch();

    const [activeCategory, setActiveCategory] = useState('');
    const [page, setPage] = useState(2);

    const statusHomepage = useSelector(
        (state: { homepage: { status: string } }) => state.homepage.status
    );

    const statusPosts = useSelector((state: any) => state.homepage.statusPosts);
    const posts = useSelector((state: any) => state.homepage.posts);
    const postsMeta = useSelector((state: any) => state.homepage.postsMeta);
    const postsTopTitle = useSelector(
        (state: any) => state.homepage.postsTopTitle
    );
    const postsTop = useSelector((state: any) => state.homepage.postsTop);
    const collectionsPosts = useSelector(
        (state: any) => state.homepage.collectionsPosts
    );

    console.log('collectionsPosts', collectionsPosts);

    const topServices = useSelector(
        (state: { homepage: any }) => state.homepage.topServices
    );
    const categories = useSelector(
        (state: { homepage: any }) => state.homepage.categories
    );

    useEffect(() => {
        if (statusPosts == 'SUCCESS') {
            dispatch(getPostsIdle());
        }
    }, [statusPosts]);

    useEffect(() => {
        if (statusPosts == 'IDLE') {
            dispatch(getPostsClear());
            setPage(2);
            let filters = '&pagination[start]=0&pagination[limit]=100';
            if (activeCategory !== '') {
                filters =
                    filters +
                    '&filters[Post_category][id][$in]=' +
                    activeCategory;
            }
            dispatch(
                getPosts({
                    filter: filters,
                })
            );
        }
    }, [activeCategory]);

    useEffect(() => {
        if (statusHomepage == 'IDLE') {
            dispatch(getHomepage());
        }

        if (typeof window !== undefined && typeof window.Swiper !== undefined) {
            // window.addEventListener('load', function(){
            new window.Swiper('.swiper', {
                loop: false,
                slidesPerView: 'auto',
                spaceBetween: 40,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
            // })
        }
    }, []);

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://normdubai.com" />
                <meta property="og:type" content="page" />
                <meta property="og:site_name" content="Нормальный Дубай" />
                <meta property="og:title" content="Нормальный Дубай" />
                <title>Нормальный Дубай</title>
                <meta
                    name="description"
                    content={
                        'Помогаем переехать в Эмираты и рассказываем, как живет русскоязычное сообщество'
                    }
                />
                <meta
                    property="og:description"
                    content="Помогаем переехать в Эмираты и рассказываем, как живет русскоязычное сообщество"
                />
                <meta
                    property="og:image"
                    content="https://normdubai.com/assets/images/og.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta
                    property="vk:image"
                    content="https://normdubai.com/assets/images/og.png"
                />
                <meta property="vk:image:width" content="537" />
                <meta property="vk:image:height" content="240" />
                <meta
                    property="twitter:image"
                    content="https://normdubai.com/assets/images/og.png"
                />
                <meta property="twitter:image:width" content="1024" />
                <meta property="twitter:image:height" content="512" />
                <meta name="twitter:title" content="Нормальный Дубай" />
                <meta
                    name="twitter:description"
                    content="Помогаем переехать в Эмираты и рассказываем, как живет русскоязычное сообщество"
                />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="page _home">
                <div className="section _hero">
                    <div className="container">
                        <div className="section__animal">
                            <div className="section__animal-text">
                                Нормальный? Ну смотря на&nbsp;кого попадешь
                            </div>
                        </div>
                        <div className="section__logo">
                            <img src="./assets/images/logo.svg" />
                        </div>
                        <div className="section__lead">
                            <h4>
                                <span>Помогаем переехать в&nbsp;Эмираты</span> и
                                рассказываем, как живет русскоязычное сообщество
                            </h4>
                        </div>
                        <div className="section__button">
                            <Button
                                className={'_default _large'}
                                href={'/services'}
                                active={true}
                            >
                                Все услуги и цены
                            </Button>
                        </div>
                        <div className="section__list">
                            {topServices.map((service: any, index: any) => {
                                return (
                                    <Link
                                        key={index}
                                        to={`/services/#category-${service.id}`}
                                        className="section__list-link"
                                    >
                                        <div className="section__list-link-preview">
                                            <img
                                                src={
                                                    domain +
                                                    service.attributes.Preview
                                                        .data.attributes.url
                                                }
                                            />
                                        </div>
                                        <div
                                            className="section__list-link-title"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    service.attributes.Title,
                                            }}
                                        ></div>
                                    </Link>
                                );
                            })}
                        </div>
                        <div className="section__slider">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    {topServices.map(
                                        (service: any, index: any) => {
                                            return (
                                                <div className="swiper-slide">
                                                    <Link
                                                        key={index}
                                                        to={`/services/#category-${service.id}`}
                                                        className="section__list-link"
                                                    >
                                                        <div className="section__list-link-preview">
                                                            <img
                                                                src={
                                                                    domain +
                                                                    service
                                                                        .attributes
                                                                        .Preview
                                                                        .data
                                                                        .attributes
                                                                        .url
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="section__list-link-title"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    service
                                                                        .attributes
                                                                        .Title,
                                                            }}
                                                        ></div>
                                                    </Link>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section _posts">
                    <div className="container _wide">
                        <div className="section__title">
                            <div className="_h1">
                                Что происходит в&nbsp;нормальном Дубае
                            </div>
                        </div>
                        <div className="section__categories">
                            {categories.map((item: any) => {
                                return (
                                    <Button
                                        key={item.value}
                                        active={activeCategory == item.value}
                                        attributes={{
                                            onClick: () => {
                                                setActiveCategory(item.value);
                                            },
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                );
                            })}
                        </div>
                        <div className="section__grid">
                            <Masonry>
                                {posts.map((post: any, index: any) => {
                                    if (page * 8 < index + 1) {
                                        return;
                                    }
                                    return (
                                        <div
                                            className="section__grid-item"
                                            key={index}
                                        >
                                            <StoryCard
                                                link={
                                                    '/posts/' +
                                                    post.attributes.Slug
                                                }
                                                IsMaskPreview={
                                                    (post.attributes
                                                        .IsMaskPreview &&
                                                        post.attributes
                                                            .IsMaskPreview) ||
                                                    false
                                                }
                                                emoji={
                                                    (post.attributes.Emoji
                                                        .data &&
                                                        domain +
                                                            post.attributes
                                                                .Emoji.data
                                                                .attributes
                                                                .url) ||
                                                    null
                                                }
                                                image={
                                                    (post.attributes.Preview
                                                        .data &&
                                                        post.attributes.Preview
                                                            .data.length == 1 &&
                                                        domain +
                                                            post.attributes
                                                                .Preview.data[0]
                                                                .attributes
                                                                .url) ||
                                                    null
                                                }
                                                images={
                                                    (post.attributes.Preview
                                                        .data &&
                                                        post.attributes.Preview
                                                            .data.length > 1 &&
                                                        post.attributes.Preview
                                                            .data) ||
                                                    null
                                                }
                                                title={post.attributes.Title}
                                                description={
                                                    post.attributes.Description
                                                }
                                                className={
                                                    (post.attributes
                                                        .Post_category.data &&
                                                        post.attributes
                                                            .Post_category.data
                                                            .id == 4 &&
                                                        '_guide') ||
                                                    ''
                                                }
                                                background={
                                                    post.attributes.Background
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </Masonry>
                        </div>
                        {page * 8 < posts.length && (
                            <div className="section__loadmore">
                                <Button
                                    className={'_default _dark _large'}
                                    attributes={{
                                        onClick: () => {
                                            setPage(page + 1);
                                        },
                                    }}
                                >
                                    Читать еще
                                </Button>
                            </div>
                        )}
                        {/* {postsMeta &&
                            postsMeta.pagination.total !== posts.length && (
                                <div className="section__loadmore">
                                    <Button
                                        className={'_default _dark _large'}
                                        attributes={{
                                            onClick: () => {
                                                if (statusPosts == 'IDLE') {
                                                    let filters =
                                                        '&pagination[start]=' +
                                                        ( posts.length - 1 ) +
                                                        '&pagination[limit]=8';
                                                    if (activeCategory !== '') {
                                                        filters =
                                                            filters +
                                                            '&filters[Post_category][id][$in]=' +
                                                            activeCategory;
                                                    }
                                                    dispatch(
                                                        getPosts({
                                                            filter: filters,
                                                        })
                                                    );
                                                }
                                            },
                                        }}
                                    >
                                        Читать еще
                                    </Button>
                                </div>
                            )} */}
                    </div>
                </div>
                {collectionsPosts.map((collection: any) => {
                    return (
                        <div className="section _selection">
                            <div className="container _wide">
                                <div className="section__title">
                                    <div className="_h1">
                                        {collection.attributes.Title}
                                    </div>
                                </div>

                                <div className="section__grid">
                                    <Masonry>
                                        {collection.attributes.Posts_types &&
                                            collection.attributes.Posts_types
                                                .length > 0 &&
                                            collection.attributes.Posts_types.map(
                                                (post: any, index: any) => {
                                                    let link = '';
                                                    let emoji = null;
                                                    let image = null;
                                                    let images = null;
                                                    let title = '';
                                                    let className = '';
                                                    let background = null;
                                                    let description = '';
                                                    if (
                                                        post.Event &&
                                                        post.Event.data
                                                    ) {
                                                        title =
                                                            post.Event.data
                                                                .attributes
                                                                .Title;
                                                        description =
                                                            post.Event.data
                                                                .attributes
                                                                .Description;
                                                        link =
                                                            '/events/' +
                                                            post.Event.data
                                                                .attributes
                                                                .Slug;
                                                        image =
                                                            (post.Event.data
                                                                .attributes
                                                                .Card_background
                                                                .data &&
                                                                domain +
                                                                    post.Event
                                                                        .data
                                                                        .attributes
                                                                        .Card_background
                                                                        .data
                                                                        .attributes
                                                                        .url) ||
                                                            null;
                                                    } else if (
                                                        post.Offer &&
                                                        post.Offer.data
                                                    ) {
                                                        title =
                                                            post.Offer.data
                                                                .attributes
                                                                .Title;
                                                        description =
                                                            post.Offer.data
                                                                .attributes
                                                                .Description;
                                                        link =
                                                            '/offers/' +
                                                            post.Offer.data
                                                                .attributes
                                                                .Slug;
                                                        emoji =
                                                            (post.Offer.data
                                                                .attributes
                                                                .Emoji.data &&
                                                                domain +
                                                                    post.Offer
                                                                        .data
                                                                        .attributes
                                                                        .Emoji
                                                                        .data
                                                                        .attributes
                                                                        .url) ||
                                                            null;
                                                        image =
                                                            (post.Offer.data
                                                                .attributes
                                                                .Preview.data &&
                                                                post.Offer.data
                                                                    .attributes
                                                                    .Preview
                                                                    .data
                                                                    .length ==
                                                                    1 &&
                                                                domain +
                                                                    post.Offer
                                                                        .data
                                                                        .attributes
                                                                        .Preview
                                                                        .data[0]
                                                                        .attributes
                                                                        .url) ||
                                                            null;
                                                    } else if (
                                                        post.Post &&
                                                        post.Post.data
                                                    ) {
                                                        title =
                                                            post.Post.data
                                                                .attributes
                                                                .Title;
                                                        description =
                                                            post.Post.data
                                                                .attributes
                                                                .Description;
                                                        link =
                                                            '/posts/' +
                                                            post.Post.data
                                                                .attributes
                                                                .Slug;
                                                        emoji =
                                                            (post.Post.data
                                                                .attributes
                                                                .Emoji.data &&
                                                                domain +
                                                                    post.Post
                                                                        .data
                                                                        .attributes
                                                                        .Emoji
                                                                        .data
                                                                        .attributes
                                                                        .url) ||
                                                            null;
                                                        image =
                                                            (post.Post.data
                                                                .attributes
                                                                .Preview.data &&
                                                                post.Post.data
                                                                    .attributes
                                                                    .Preview
                                                                    .data
                                                                    .length ==
                                                                    1 &&
                                                                domain +
                                                                    post.Post
                                                                        .data
                                                                        .attributes
                                                                        .Preview
                                                                        .data[0]
                                                                        .attributes
                                                                        .url) ||
                                                            null;
                                                        images =
                                                            (post.Post.data
                                                                .attributes
                                                                .Preview.data &&
                                                                post.Post.data
                                                                    .attributes
                                                                    .Preview
                                                                    .data
                                                                    .length >
                                                                    1 &&
                                                                post.Post.data
                                                                    .attributes
                                                                    .Preview
                                                                    .data) ||
                                                            null;
                                                        className =
                                                            (post.Post.data
                                                                .attributes
                                                                .Post_category
                                                                .data &&
                                                                post.Post.data
                                                                    .attributes
                                                                    .Post_category
                                                                    .data.id ==
                                                                    4 &&
                                                                '_guide') ||
                                                            '';
                                                        background =
                                                            post.Post.data
                                                                .attributes
                                                                .Background;
                                                    }
                                                    return (
                                                        <div
                                                            className="section__grid-item"
                                                            key={index}
                                                        >
                                                            <StoryCard
                                                                link={link}
                                                                emoji={emoji}
                                                                image={image}
                                                                images={images}
                                                                title={title}
                                                                description={
                                                                    description
                                                                }
                                                                className={
                                                                    className
                                                                }
                                                                background={
                                                                    background
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        {collection.attributes.Posts.data.map(
                                            (post: any, index: any) => {
                                                return (
                                                    <div
                                                        className="section__grid-item"
                                                        key={index}
                                                    >
                                                        <StoryCard
                                                            link={
                                                                '/posts/' +
                                                                post.attributes
                                                                    .Slug
                                                            }
                                                            emoji={
                                                                (post.attributes
                                                                    .Emoji
                                                                    .data &&
                                                                    domain +
                                                                        post
                                                                            .attributes
                                                                            .Emoji
                                                                            .data
                                                                            .attributes
                                                                            .url) ||
                                                                null
                                                            }
                                                            image={
                                                                (post.attributes
                                                                    .Preview
                                                                    .data &&
                                                                    post
                                                                        .attributes
                                                                        .Preview
                                                                        .data
                                                                        .length ==
                                                                        1 &&
                                                                    domain +
                                                                        post
                                                                            .attributes
                                                                            .Preview
                                                                            .data[0]
                                                                            .attributes
                                                                            .url) ||
                                                                null
                                                            }
                                                            images={
                                                                (post.attributes
                                                                    .Preview
                                                                    .data &&
                                                                    post
                                                                        .attributes
                                                                        .Preview
                                                                        .data
                                                                        .length >
                                                                        1 &&
                                                                    post
                                                                        .attributes
                                                                        .Preview
                                                                        .data) ||
                                                                null
                                                            }
                                                            title={
                                                                post.attributes
                                                                    .Title
                                                            }
                                                            description={
                                                                post.attributes
                                                                    .Description
                                                            }
                                                            className={
                                                                (post.attributes
                                                                    .Post_category
                                                                    .data &&
                                                                    post
                                                                        .attributes
                                                                        .Post_category
                                                                        .data
                                                                        .id ==
                                                                        4 &&
                                                                    '_guide') ||
                                                                ''
                                                            }
                                                            background={
                                                                post.attributes
                                                                    .Background
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Masonry>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Home;
